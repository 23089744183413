.background {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: #00000010;
  margin-bottom: 3rem;
}

.adSection {
  margin-bottom: 1rem;
  @media (min-width: 850px) {
    padding-left: 8.33%;
  }
  @media (max-width: 850px) {
    padding-left: 3%;
  }
}

.section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  @media (min-width: 850px) {
    padding-left: 8.33%;
  }
  @media (max-width: 850px) {
    padding-left: 3%;
  }
}

.textGrayP {
  white-space: pre-line;
  color: var(--color-m88-alt-text);
}

.text {
  margin-bottom: 1.5rem;
  @media (min-width: 920px) {
    margin-right: 8rem;
    max-width: 55%;
  }
}

.textLink {
  color: rgba(255, 255, 255, 0.68);
  text-decoration: none;
  font-weight: bold;
}
