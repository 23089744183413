$blackM88: #0e0f14;
$blackM91: #1e2132;
$blackM96: #282a3a;
$blackM100: #313443;

$blueM92: #5446f0;
$blueM96: #5b4df1;
$blueM100: #6255f1;

$special: #14fed4;

// Root

@use "fonts.scss";

:root {
  --color-text: rgba(255, 255, 255, 1);
  --root-text-color: #ffffff;
  --color-alt-text: rgba(255, 255, 255, 0.8);
  --color-base: #313443;
  --color-blue: #174fde;
  --color-blue-alt: #5b4df1;

  // m88
  --color-m88-text: rgba(255, 255, 255, 0.88);
  --color-m88-alt-text: rgba(255, 255, 255, 0.68);
  --color-m88-base: #151829;

  // m92
  --color-m92-text: rgba(255, 255, 255, 0.92);
  --color-m92-alt-text: rgba(255, 255, 255, 0.72);
  --color-m92-base: #16181f;

  // m96
  --color-m96-text: rgba(255, 255, 255, 0.96);
  --color-m96-alt-text: rgba(255, 255, 255, 0.76);
  --color-m96-base: #1e2028;
  --color-m96-alt-base: #2e313f;

  // m100
  --color-m100-text: rgba(255, 255, 255, 1);
  --color-m100-alt-text: rgba(255, 255, 255, 0.8);
  --color-m100-base: #313443;
}

body {
  margin: 0;
  padding: 0;
  background-color: $blackM88;

  %font-shared {
    color: #ffffff;
    font-family: Manrope;
    font-weight: bold;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0em;
    text-align: left;
  }

  h1 {
    color: var(--color-text);
    font-weight: 800;
    line-height: 150%;
    font-size: 18px;
    @extend %font-shared;
  }

  h2 {
    color: var(--color-text);
    font-weight: 600;
    line-height: 150%;
    font-size: 16px;
    @extend %font-shared;
  }

  h3 {
    color: var(--color-text);
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    margin: 0;
    @extend %font-shared;
  }

  h4 {
    color: var(--color-text);
    font-weight: 700;
    line-height: 150%;
    font-size: 12px;
    @extend %font-shared;
  }

  h5 {
    color: var(--color-text);
    font-weight: 700;
    line-height: 150%;
    font-size: 12px;
    @extend %font-shared;
  }

  h6 {
    color: var(--color-text);
    font-weight: 500;
    line-height: 150%;
    font-size: 12px;
    @extend %font-shared;
  }

  p,
  a {
    color: var(--color-text);
    font-weight: 500;
    line-height: 150%;
    font-size: 13px;
    @extend %font-shared;
  }

  th {
    color: var(--color-text);
    font-weight: bold;
    font-size: 12px;
    @extend %font-shared;
  }

  td {
    color: var(--color-alt-text);
    font-weight: 500;
    font-size: 13px;
    @extend %font-shared;
  }

  span {
    color: var(--color-text);
  }

  label {
    margin-bottom: 10px;
  }

  a:hover {
    color: var(--root-text-color);
  }

  p {
    color: var(--color-alt-text);
  }

  a {
    transition: color 0.1s;
    text-decoration: none;
  }

  select {
    padding-right: 1rem;
  }

  .box_hover:hover {
    border-radius: 10px;
    background-color: #282a3a;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $blackM100;
}

// toggleswitch

.switch {
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
  margin-top: 5px;
  width: 37px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.darkSlider {
  @extend .slider;
  background-color: var(--color-m96-base);
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .darkSlider {
  background-color: var(--color-m96-alt-base);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

// tables

.column {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0 auto;
  margin-top: 0.5rem;
}

.row {
  flex: 1;
}

.nameRow {
  margin-right: 0.5rem;
  height: 18px;
}

// smaller screen table

.smallestPhoneRow {
  flex: 1;
  @media (max-width: 440px) {
    display: none;
  }
}

.smallPhoneRow {
  flex: 1;
  @media (max-width: 570px) {
    display: none;
  }
}

.tabletRow {
  flex: 1;
  @media (max-width: 700px) {
    display: none;
  }
}

.phoneRow {
  flex: 1;
  @media (max-width: 600px) {
    display: none;
  }
}

// page

.hideOnSmallScreen {
  @media screen and (max-width: 1800px) {
    display: none;
  }
}

.pageColumn {
  @media screen and (min-width: 1800px) {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
  }
}

.pageRow {
  @media screen and (min-width: 1800px) {
    flex: 0;
    min-width: 800px;
  }
  @media screen and (max-width: 1800px) {
    flex: 100%;
  }
}

.container {
  @media (min-width: 850px) {
    padding-left: 8.33%;
  }
  @media (max-width: 850px) {
    padding-left: 2%;
  }
}

// buttons

%smallSearchBox {
  box-sizing: border-box;
  color: var(--color-m96-text);
  border: none;
  border-radius: 10px;
  background: #1e2028;
  width: 191px;
  height: 30px;
  padding-left: 1rem;
  margin-bottom: 1rem;
  font-family: Manrope;
  font-weight: medium;
  font-style: normal;
  margin-right: 1rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  ::placeholder {
    color: var(--color-m96-alt-text);
  }
}

.smallSearchBox {
  @extend %smallSearchBox;
}

.homePlayerSearchBox {
  @extend %smallSearchBox;

  color: rgba(255, 255, 255, 0.7);
  border-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  width: auto;
  height: 50px;
  padding-left: 2rem;
  margin-right: 0.2rem;
  filter: none;
  @media (min-width: 750px) {
    width: 20rem;
    margin-left: -2rem;
  }
  @media (max-width: 750px) {
    max-width: 20rem;
    width: 95%;
  }
  @media (min-width: 1000px) {
    width: 621px;
  }
}

%searchBox {
  @extend %smallSearchBox;

  width: auto;
  height: 50px;
  padding-left: 2rem;
  @media (min-width: 710px) {
    width: 30rem;
  }
  @media (max-width: 710px) {
    max-width: 30rem;
    width: 95%;
  }
}

.searchBox {
  @extend %searchBox;
}

.bf2042SearchBox {
  @extend %searchBox;
  border-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-right: 0;
  filter: none;

  @media (min-width: 710px) {
    width: 20rem;
  }
  @media (max-width: 710px) {
    max-width: 20rem;
  }
}

// buttonLinks
.buttonLink {
  padding: 10px 20px;
  background: var(--color-m96-base);
  border-radius: 10px;
  transition: all 0.1s;
  display: flex;
  flex-grow: 1;
  width: auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  &:hover {
    background: var(--color-m96-alt-base);
  }
}

.primaryButtonLink {
  @extend .buttonLink;

  background: var(--color-blue);
  &:hover {
    background: var(--color-blue-alt);
  }
}

.bigButtonLink {
  @extend .buttonLink;

  width: 134px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-size: 0.9rem;
}

// buttons

.bigButtonSecondary {
  background: #1e2028;
  color: var(--color-m96-text);
  border: none;
  margin-bottom: 1rem;
  border-radius: 10px;
  height: 50px;
  width: 134px;
  margin-right: 1rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-family: Manrope;
  font-weight: medium;
  font-style: normal;
  cursor: pointer;
  &:hover {
    background: var(--color-m96-base);
  }
}

.bigButtonPrimaryD {
  @extend .bigButtonSecondary;

  background: var(--color-blue);
  width: auto;
  min-width: 158px;
  filter: none;
  &:hover {
    background: var(--color-blue-alt);
  }
}

.bigButtonPrimary {
  @extend .bigButtonPrimaryD;

  @media screen and (max-width: 530px) {
    width: 100%;
  }
}

.bigButtonSecondaryBox {
  @extend .bigButtonSecondary;

  background: var(--color-m96-base);
  &:hover {
    background: #313443;
  }
}

.smallButton {
  @extend .bigButtonSecondary;

  height: 30px;
  padding: 0 24px;
  width: auto;
  margin-right: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.smallButtonPrimary {
  @extend .smallButton;

  background: var(--color-m96-base);
  &:hover {
    background: #313443;
  }
}

.smallButtonSecondary {
  @extend .smallButton;

  border: none;
  height: auto;
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
  margin-right: 1rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-sizing: borfer-box;
}

// select

.selectSecondary {
  background: #1e2028;
  color: var(--color-m96-text);
  border-radius: 10px;
  border: none;
  height: 30px;
  padding: 0 24px;
  font-family: Manrope;
  font-weight: medium;
  font-style: normal;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 3px;
  option {
    background: rgba(0, 0, 0, 0.8);
  }
}

.bigSelectSecondary {
  @extend .selectSecondary;

  height: 50px;
  margin-right: 1rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: none;
  margin-bottom: 1rem;
  padding-left: 18px;
  background-position-y: 13px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 13px;
}

.homePlayerBigSelectSecondary {
  @extend .bigSelectSecondary;

  width: 128px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 25px;
  filter: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='rgb(255,255,255)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 13px;
}

.bf2042BigSelectSecondary {
  @extend .homePlayerBigSelectSecondary;

  width: 10rem;
  background: #1e2028;
  color: #929292;
  padding-left: 18px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='rgb(131,131,131)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 13px;
}

.selectPrimary {
  @extend .selectSecondary;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-bottom: 1rem;
}

// align page

.alignT {
  display: flex;
  flex-wrap: wrap;
}

.align {
  @extend .alignT;
  align-items: center;
}

.alignS {
  @extend .align;

  justify-content: space-around;
  width: 100%;
}

.alignW {
  @extend .align;

  flex-wrap: nowrap;
}

.alignServerImg {
  @media (min-width: 540px) {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
}

// pageBox

.box {
  max-width: 45rem;
  margin: 0 12px 18px 0;

  .wrap {
    background: var(--color-m92-base);
    align-self: flex-start;
    display: flex;
    box-sizing: border-box;

    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .inner {
    flex-grow: 2;
    padding: 12px 24px 24px 24px;
    box-sizing: border-box;
    display: flex;
    overflow-y: auto;
    max-width: 45rem;
    max-height: 30rem;
    flex-direction: column;
    //display: flex;
  }
}

// other

.circle {
  height: 60px;
  width: 60px;
  margin-right: 1.5rem;
  background-color: var(--color-m92-base);
  border-radius: 50%;
}
